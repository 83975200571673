/* Import our fonts. */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap");
/* The peugeot font is only used for ... well... peugeot. */
@font-face {
  font-family: "Peugeot";
  src: url("../../fonts/PeugeotNew-Light.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Peugeot";
  src: url("../../fonts/PeugeotNew-Regular.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Peugeot";
  src: url("../../fonts/PeugeotNew-Bold.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Peugeot";
  src: url("../../fonts/PeugeotNew-Black.ttf") format("truetype");
  font-weight: 600;
}

/* Here's all the other stuff */
body {
  background: url("../../images/Skybackground.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16pt;
}

h1 {
  font-size: 50pt;
  font-weight: 200;
}

.Instance {
  text-align: center;
  background: url("../../images/Skybackground.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  /* This stuff is from the header class. Maybe we need to move it later? */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16pt;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #363636;
}

.App-link {
  color: #61dafb;
}

.RoundedButton:link,
.RoundedButton:visited {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding-top: 10px;
  width: 345px;
  height: 55px;
  font-size: 18pt;
  font-weight: 400;
  border-radius: 30px;
}

.PrimaryButton:link,
.PrimaryButton:visited {
  background-color: #81b6bd;
}
.PrimaryButton:hover,
.PrimaryButton:active {
  background-color: #888888;
}
.SecondaryButton:link,
.SecondaryButton:visited {
  background-color: black;
}
.SecondaryButton:hover,
.SecondaryButton:active {
  background-color: #888888;
}
