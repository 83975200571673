body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
label {
  display: block;
  text-align: left;
  width: 88%;
}

.formHidden {
  display: none;
}
.formLogo {
  width: 411px;
  height: 73px;
}

.footNote {
  font-size: 12px;
  padding: 0px;
}

.formBG {
  font-size: 16px;
  background-color: white;
  width: 50vw;
  height: 100vh;
  margin: auto;
  padding: 50px;
  text-align: center;
}

.formfield {
  text-align: center;
  margin: auto;
  padding: 10px;
  size: 100px;
  width: 50vw;
  height: 73px;
}

input,
textarea {
  border: 1px solid #a8a8a8;
  background-color: #fafafa;
}

.sendButton {
  font-size: 16px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 20px;
  width: 80px;
  height: 40px;
  margin: auto;
  border: 0px solid #a8a8a8;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
