.LoginForm {
  background-color: #79a8b8;
  border-radius: 0.5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 1em;
  text-align: left;
  width: 450px;
  filter: drop-shadow(0px 0px 10px #0000005a);
}

.LoginButton {
  color: white;
  text-align: center;
  padding: 0px 20px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 48px;
  font-size: 16pt;
  font-weight: 600;
  /* border-radius: 6px; */
  border: none;
  text-transform: capitalize;
}
.PrimaryButton {
  background-color: #81b6bd;
}
.PrimaryButton:hover {
  background-color: #34bbcc;
}
.NativeLoginButton {
  width: 200px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.5);
}

.SecondaryButton {
  background-color: #b7b7b7;
}
.SecondaryButton:hover {
  background-color: #505050;
}

.GuestButton {
  background-color: #007eb5;
}

.GuestButton:hover {
  background-color: #108ec4;
}

.LoginField {
  background-color: white;
  height: 50px;
  font-size: 18pt;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
  border: none;
}
